<template>
  <div class="content-layout-fixer" id="divUsersManualHomeWrapper">
    <div class="video-wrapper">
      <BrandVimeoPlayer
        v-if="mediaData"
        :data="mediaData"
        :id="mediaData.videoId"
        :logger="{ interval: 5 }"
        @setPercentageValue="setPercentageValue($event)"
      />
    </div>
    <div class="video-list-holder">
      <div class="video-list-wrapper">
        <div v-for="item in mediaItems" :key="item.id">
          <BrandMediaListItem
            :text="item.title"
            :mediaSource="item"
            :showPercentage="false"
            :percentage="percentage"
            @handleRoute="handleVideoRouting(item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import BrandMediaListItem from '@/components/brand/BrandMediaListItem/BrandMediaListItem.vue';
import vimeoUtils from '@/mixins/vimeoUtils.js';
import Content from '@/services/Api/content';

export default {
  name: 'UserManualNavigation',
  components: {
    BrandVimeoPlayer,
    BrandMediaListItem,
  },
  mixins: [vimeoUtils],
  data() {
    return {
      mediaData: null,
      mediaItems: [],
      percentage: 0,
      hasRefreshedAfter80: false,
    };
  },
  methods: {
    handleVideoRouting(item) {
      this.mediaData = null;
      this.$route.meta.breadcrumb[2].name = item.title;
      setTimeout(() => {
        this.mediaData = item;
        window.scrollTo(0, 0);
      }, 200);
    },
    getUserGuideList() {
      const isFromRosette = this.$route.query.isFromRosette;

      Content.getUserGuide(isFromRosette).then(res => {
        const { videos } = res.data.Data;
        this.mediaItems = this.getVideoThumbs(videos);
        this.mediaData = videos[0];
        this.percentage = videos[0].log.percent;
        this.$route.meta.breadcrumb[2].name = videos[0]?.title ?? '';
      });
    },
    setPercentageValue(percentage) {
      this.percentage = percentage;
      if (
        percentage > 80 &&
        this.mediaData?.rosetteInput?.isRosetteTask &&
        !this.hasRefreshedAfter80
      ) {
        this.getUserGuideList();
        this.hasRefreshedAfter80 = true;
      }
    },
  },
  created() {
    this.getUserGuideList();
  },
};
</script>
<style lang="scss" scoped>
.video-list-holder {
  border-top: 1px solid palette-color-level('grey', '20');
  margin: palette-space-level(20) 0;
  padding-left: palette-space-level(20);

  .video-list-wrapper {
    margin-top: palette-space-level(20);
    display: grid;
    grid-template-columns: repeat(3, 321px);
    grid-gap: palette-space-level(20);
  }

  .video-list-wrapper > div {
    border: 1px solid palette-color-level('grey', '20');
  }
}
</style>
